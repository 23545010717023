<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>App更新设置</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info">
            <div>
                <el-tabs v-model="currentTab" type="card" @tab-click="selectTab">
                    <el-tab-pane v-for="item in editableTabs" :key="item.name" :label="item.title" :name="item.name">
                        <el-form :model="form" label-position="left" :rules="rules" label-width="100px" ref="form" :v-loading="loading">
                            <el-form-item label="版本号" prop="version">
                                <el-input v-model="form.version" placeholder="请输入版本号" size="small" style="width: 200px;"></el-input>
                            </el-form-item>
                            <el-form-item label="下载地址" prop="download_url">
                                <el-input v-model="form.download_url" placeholder="请输入下载地址" size="small" style="width: 500px;"></el-input>
                            </el-form-item>
                            <el-form-item label="更新内容" prop="content">
                                <editor @onChange="onChange"
                                    :content="form.content">
                                </editor>
                            </el-form-item>  
                            <el-form-item label="强制更新" prop="update_force">
                                <el-select v-model="form.update_force" placeholder="请选是否择强制更新" size="mini">
                                    <el-option v-for="item in options" :key="item.value" :value="item.value" :label="item.label"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-button class="margin-l-sm" size="small" type="primary" @click="validateForm()" :loading="loading">保存</el-button>
                        </el-form>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex"
import editor from "../../../components/editor"
export default {
    name: "appUpdate",
    components: { editor },
    data() {
        return {
            editableTabs: [
                {
                    "name": "0",
                    "title": "android"
                },
                {
                    "name": "1",
                    "title": "ios"
                }
            ],
            currentTab: '0',
            imageUrl: config.imageUrl,
            form: {},
            options: [
                {
                    "label": "否",
                    "value": 0
                },
                {
                    "label": "是",
                    "value": 1
                }
            ],
            loading: false,
            rules : {
                version: [
                    { required: true, message: '请输入版本号', trigger: 'blur' },
                    { max: 50, message: '长度不能超过50个字符', trigger: 'blur' }
                ],
                download_url: [
                    { required: true, message: '请输入下载地址', trigger: 'blur' },
                    { max: 255, message: '长度不能超过255个字符', trigger: 'blur' }
                ],
                content: [
                    { required: true, message: '请输入更新内容', trigger: 'blur' },
                    { max: 500, message: '长度不能超过500个字符', trigger: 'blur' }
                ],
                update_force: [
                { required: true, message: '请选择是否强制更新', trigger: 'blur' },
                ]
            }
        }
    },
    methods: {
        ...mapActions("appUpdate",["getAppUpdate","saveAppUpdate"]),
        // 表单提交前
        validateForm() {
            this.$refs.form[this.currentTab].validate(async valid => {
                if (!valid) {
                    return
                }
                this.submit()
            })
        },
        //提交表单保存
        async submit() {
           try{
                this.loading = true
                let platform = this.currentTab == '0' ? 1 : 2
                this.form.platform = platform
                const { ret , data } = await this.saveAppUpdate(this.form)
                if(ret !== 0){
                    return 
                }
                this.form = data
                this.$message.success('保存成功!')
           }finally{
            this.loading = false
           }
        },
        selectTab(tab, event) {
            this.currentTab = tab.index
            this.initAppUpdate()
        },
        onChange(content){
            this.form.content = content
        },
        async initAppUpdate(){
            this.loading = true
            let platform = this.currentTab == 0 ? 1 : 2
            const { ret , data } = await this.getAppUpdate({"platform": platform})
            this.loading = false
            if(ret !== 0){
                return 
            }
            this.form = data
        }
    },
    // 挂载
    async mounted() {
       await this.initAppUpdate()
    }
}
</script>
<style scoped>
.el-radio,
.el-checkbox {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0;
}

.el-radio,
.el-radio__label,
.el-checkbox,
.el-checkbox__label {
    display: flex;
    align-items: center;
}
</style>
